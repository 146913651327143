<template>
  <b-row>

    <b-col>
      <b-row>
        <b-col md="auto">
          <v-date-picker
              v-model="range"
              is-range
              :masks="masks"
              :columns="2"
              locale="ru"
              show-iso-weeknumbers
              title-position="right"
              mode="date"
              :timezone="'UTC'"
              is24hr
          >
          </v-date-picker>
        </b-col>
        <b-col>
          <b-row>
            <b-col>
              Всего: <br>
              продаж: {{calcSumStats.allqty}}, <br>
              пришло от вб: {{calcSumStats.allforPay}}, <br>
              общий закуп: {{calcSumStats.allzakup}}, <br>
              <b>доход: {{calcSumStats.alldohod}}</b>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <hr>
              Даты: {{allDates}}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
              :items="reportLog"
              :fields="fields"
              stacked="md"
              show-empty
              small
              headVariant="light"
              bordered
              responsive
              :busy="isLoading"
          >
            <template #table-busy>
              <div class="text-center text-secondary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </template>
            <template #empty="">
              <div role="alert" aria-live="polite"><div class="text-center my-2">Список пуст.</div></div>
            </template>

            <template #head(dohod)="item">
              <span class="text-success">{{ item.label }} </span>
             <b-icon id="tooltip-target-1" icon="exclamation-circle-fill" variant="dark"></b-icon>
              <b-tooltip target="tooltip-target-1" triggers="hover">
                Пришло от вб минус закупочная минус 55 руб
              </b-tooltip>
            </template>
            <template #head(forPay)="item">
               {{ item.label }}
             <b-icon id="tooltip-target-2" icon="exclamation-circle-fill" variant="dark"></b-icon>
              <b-tooltip target="tooltip-target-2" triggers="hover">
                forPay
              </b-tooltip>
            </template>

            <template #cell(createdAt)="{item}">
              {{item.createdAt}} <br>
              {{formatDate(item.createdAt)}} <br>
            </template>

          </b-table>
        </b-col>
      </b-row>
    </b-col>

  </b-row>

</template>

<style lang="scss">
</style>

<script>

// import _ from "lodash";

export default {
  name: "sales-report-page",
  data() {
    return {
      range: {
        start: new Date(new Date().setDate(new Date().getDate() - 8)),
        end: new Date(new Date().setDate(new Date().getDate() - 1)),
      },
      masks: {
        input: 'YYYY-MM-DD h:mm A',
      },
      reportLog: [],
      fields: [
        { key: 'vipModel', label: 'Модель', sortable: true, sortDirection: 'desc' },
        { key: 'qty', label: 'Кол-во продаж', sortable: true, sortDirection: 'desc' },
        { key: 'forPay', label: 'Пришло от вб', sortable: true, sortDirection: 'desc' },
        { key: 'zakup', label: 'Сумма закупа', sortable: true, sortDirection: 'desc' },
        { key: 'dohod', label: 'Доход', sortable: true, sortDirection: 'desc' },
      ],
      isLoading: false,
      allDates: []
    };
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});

    this.getReport()
  },
  methods: {
    getReport() {
      const _ = require("lodash")
      this.isLoading=true
      fetch(`${process.env.VUE_APP_SERVER_API}/sales-report/getReport/${this.range.start}/${this.range.end}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            console.log(resp)
            if (resp.status) {
              this.reportLog =_.orderBy(_.chain(resp.items)
                  .groupBy("model")
                  .map((value, key) => ({
                    vipModel: key,
                    qty: value.reduce((t, {qty})=>t+qty,0),
                    forPay: Math.ceil(value.reduce((t, {sumForPay})=>t+sumForPay,0)),
                    dohod: Math.ceil(value.reduce((t, {sumDohod})=>t+sumDohod,0)),
                    zakup: Math.ceil(value.reduce((t, {zakup})=>t+parseInt(zakup),0)),
                    // weekNumber, dates,
                  }))
                  .value(), ['dohod'], ['desc'])
              this.allDates=Array.from(new Set(resp.items.map(e=>e.date)))
              console.log(this.reportLog)
              this.isLoading=false
            }
            // if (!resp.status) this.alertMsg('Ошибка загрузки выкупов', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          })
    },
    formatDate(curDate) {
      console.log(curDate)
      console.log(typeof curDate)
      const dd = new Date(curDate)
      return `${dd.getUTCDate()}.${dd.getUTCMonth()}.${dd.getUTCFullYear()} ${dd.getHours()}:${dd.getMinutes()}`
    }
  },
  watch: {
    'range': function () {
      this.getReport()
    }
  },
  computed: {
    // filteredLogs: function () {
    //   if (this.range.start.getTime() == this.range.end.getTime()) return this.reportLog.filter(e=>(new Date(e.createdAt)).getDate() == this.range.start.getDate()-1)
    //   return this.reportLog.filter(e=>new Date(e.createdAt) >= this.range.start && new Date(e.createdAt) <= this.range.end)
    // },
    calcSumStats: function() {
      return {
        allqty: this.reportLog.filter(e=>e.vipModel!='not found').reduce((t, {qty})=>t+parseInt(qty),0),// this.filteredLogs.filter(e=>e.action=='open page' && e.result.code==0).length,
        allforPay: this.reportLog.filter(e=>e.vipModel!='not found').reduce((t, {forPay})=>t+parseInt(forPay),0),// this.filteredLogs.filter(e=>e.action=='open page' && e.result.code==0).length,
        alldohod: this.reportLog.filter(e=>e.vipModel!='not found').reduce((t, {dohod})=>t+parseInt(dohod),0),// this.filteredLogs.filter(e=>e.action=='open page' && e.result.code==0).length,
        allzakup: this.reportLog.filter(e=>e.vipModel!='not found').reduce((t, {zakup})=>t+parseInt(zakup),0)// this.filteredLogs.filter(e=>e.action=='open page' && e.result.code==0).length,
      }
    }
  },
};
</script>